/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  --background: white;
  --primary-gradient: linear-gradient(
    145deg,
    rgba(82, 96, 157, 1) 0%,
    rgba(61, 165, 194, 1) 100%
  );

  --primary-gradient-90: linear-gradient(
    90deg,
    rgba(82, 96, 157, 1) 0%,
    rgba(61, 165, 194, 1) 100%
  );

  --marketing-blue: #7cbae264;

  --ion-text-color: #1a1919;
  --ion-text-color-rgb: 26, 25, 25;

  --ion-color-primary-dark: #4e3a82;
  --ion-color-primary-dark-rgb: 78, 58, 130;
  --ion-color-primary-dark-contrast: #ffffff;
  --ion-color-primary-dark-contrast-rgb: 255, 255, 255;
  --ion-color-primary-dark-shade: #453372;
  --ion-color-primary-dark-tint: #604e8f;

  --ion-color-primary-light: #a4accc;
  --ion-color-primary-light-rgb: 164, 172, 204;
  --ion-color-primary-light-contrast: #000000;
  --ion-color-primary-light-contrast-rgb: 0, 0, 0;
  --ion-color-primary-light-shade: #9097b4;
  --ion-color-primary-light-tint: #adb4d1;

  --ion-color-primary: #52609d;
  --ion-color-primary-rgb: 82, 96, 157;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #48548a;
  --ion-color-primary-tint: #6370a7;

  --ion-color-secondary-dark: #6ab2d7;
  --ion-color-secondary-dark-rgb: 106, 178, 215;
  --ion-color-secondary-dark-contrast: #000000;
  --ion-color-secondary-dark-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-dark-shade: #5d9dbd;
  --ion-color-secondary-dark-tint: #79badb;

  --ion-color-secondary-light: #d7eff5;
  --ion-color-secondary-light-rgb: 215, 239, 245;
  --ion-color-secondary-light-contrast: #000000;
  --ion-color-secondary-light-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-light-shade: #bdd2d8;
  --ion-color-secondary-light-tint: #dbf1f6;

  --ion-color-secondary: #b4deed;
  --ion-color-secondary-rgb: 180, 222, 237;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #9ec3d1;
  --ion-color-secondary-tint: #bce1ef;

  /** tertiary **/
  --ion-color-tertiary: #a4accc;
  --ion-color-tertiary-rgb: 164, 172, 204;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #9097b4;
  --ion-color-tertiary-tint: #adb4d1;

  --ion-color-alternate: #3da5c2;
  --ion-color-alternate-rgb: 61, 165, 194;
  --ion-color-alternate-contrast: white;
  --ion-color-alternate-contrast-rgb: 0, 0, 0;
  --ion-color-alternate-shade: #3691ab;
  --ion-color-alternate-tint: #50aec8;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-periwinkle: #8a86e2;
  --ion-color-periwinkle-rgb: 138, 134, 226;
  --ion-color-periwinkle-contrast: #ffffff;
  --ion-color-periwinkle-contrast-rgb: 255, 255, 255;
  --ion-color-periwinkle-shade: #7976c7;
  --ion-color-periwinkle-tint: #9692e5;

  --ion-color-basketball: #fb5e1c;
  --ion-color-basketball-rgb: 251, 94, 28;
  --ion-color-basketball-contrast: #000000;
  --ion-color-basketball-contrast-rgb: 0, 0, 0;
  --ion-color-basketball-shade: #dd5319;
  --ion-color-basketball-tint: #fb6e33;

  --ion-color-light-orange: #ffe0bd;
  --ion-color-light-orange-rgb: 255, 224, 189;
  --ion-color-light-orange-contrast: #000000;
  --ion-color-light-orange-contrast-rgb: 0, 0, 0;
  --ion-color-light-orange-shade: #e0c5a6;
  --ion-color-light-orange-tint: #ffe3c4;

  --ion-color-dark-gray: #535353;
  --ion-color-dark-gray-rgb: 83, 83, 83;
  --ion-color-dark-gray-contrast: #ffffff;
  --ion-color-dark-gray-contrast-rgb: 255, 255, 255;
  --ion-color-dark-gray-shade: #494949;
  --ion-color-dark-gray-tint: #646464;

  --ion-color-step-50: #f4f4f4;
  --ion-color-step-100: #e8e8e8;
  --ion-color-step-150: #dddddd;
  --ion-color-step-200: #d1d1d1;
  --ion-color-step-250: #c6c6c6;
  --ion-color-step-300: #bababa;
  --ion-color-step-350: #afafaf;
  --ion-color-step-400: #a3a3a3;
  --ion-color-step-450: #989898;
  --ion-color-step-500: #8d8c8c;
  --ion-color-step-550: #818181;
  --ion-color-step-600: #767575;
  --ion-color-step-650: #6a6a6a;
  --ion-color-step-700: #5f5e5e;
  --ion-color-step-750: #535353;
  --ion-color-step-800: #484747;
  --ion-color-step-850: #3c3c3c;
  --ion-color-step-900: #313030;
  --ion-color-step-950: #252525;
}

.ion-color-dark-gray {
  --ion-color-base: var(--ion-color-dark-gray);
  --ion-color-base-rgb: var(--ion-color-dark-gray-rgb);
  --ion-color-contrast: var(--ion-color-dark-gray-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dark-gray-contrast-rgb);
  --ion-color-shade: var(--ion-color-dark-gray-shade);
  --ion-color-tint: var(--ion-color-dark-gray-tint);
}

.ion-color-light-orange {
  --ion-color-base: var(--ion-color-light-orange);
  --ion-color-base-rgb: var(--ion-color-light-orange-rgb);
  --ion-color-contrast: var(--ion-color-light-orange-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-orange-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-orange-shade);
  --ion-color-tint: var(--ion-color-light-orange-tint);
}

.ion-color-basketball {
  --ion-color-base: var(--ion-color-basketball);
  --ion-color-base-rgb: var(--ion-color-basketball-rgb);
  --ion-color-contrast: var(--ion-color-basketball-contrast);
  --ion-color-contrast-rgb: var(--ion-color-basketball-contrast-rgb);
  --ion-color-shade: var(--ion-color-basketball-shade);
  --ion-color-tint: var(--ion-color-basketball-tint);
}

.ion-color-alternate {
  --ion-color-base: var(--ion-color-alternate);
  --ion-color-base-rgb: var(--ion-color-alternate-rgb);
  --ion-color-contrast: var(--ion-color-alternate-contrast);
  --ion-color-contrast-rgb: var(--ion-color-alternate-contrast-rgb);
  --ion-color-shade: var(--ion-color-alternate-shade);
  --ion-color-tint: var(--ion-color-alternate-tint);
}

.ion-color-periwinkle {
  --ion-color-base: var(--ion-color-periwinkle);
  --ion-color-base-rgb: var(--ion-color-periwinkle-rgb);
  --ion-color-contrast: var(--ion-color-periwinkle-contrast);
  --ion-color-contrast-rgb: var(--ion-color-periwinkle-contrast-rgb);
  --ion-color-shade: var(--ion-color-periwinkle-shade);
  --ion-color-tint: var(--ion-color-periwinkle-tint);
}

.ion-color-primary-light {
  --ion-color-base: var(--ion-color-primary-light);
  --ion-color-base-rgb: var(--ion-color-primary-light-rgb);
  --ion-color-contrast: var(--ion-color-primary-light-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary-light-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary-light-shade);
  --ion-color-tint: var(--ion-color-primary-light-tint);
}

h1,
h2,
.title {
  font-family: 'Fugaz One', cursive;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3,
h4,
button,
ion-button,
.subtitle,
.roboto-italic,
.link-button,
.label {
  font-family: 'Roboto', sans-serif;
  font-style: italic;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.fanchor {
  color: var(--ion-color-primary);
  cursor: pointer;
  text-decoration: underline;
}

*,
body {
  font-family: 'Roboto', sans-serif;
}

.line-through {
  text-decoration: line-through;
}

ion-item {
  --border-color: var(--ion-color-light);
}

ion-list {
  padding: 0 !important;
}

ion-button {
  --border-radius: 20px;
}

ion-item-divider {
  --background: var(--ion-color-light);
  border-top: solid 1px rgb(225, 225, 225);
  border-bottom: solid 1px rgb(225, 225, 225);
}

.flex-spacer {
  flex: 1 1 auto;
}

.menu {
  --background: var(--ion-color-light);
  --width: 200px;
  --max-width: 200px;
}

ion-split-pane {
  background: linear-gradient(
    -90deg,
    rgba(0, 0, 0, 0.14) 100%,
    rgba(255, 255, 255, 1) 80%
  );
  --border: none;
  --side-width: 200px;
  --side-min-width: 200px;
  --side-max-width: 200px;
}

#main {
  background: white;
}

/* @media (prefers-color-scheme: dark) {
  body {
    --ion-color-primary: #52609d;
    --ion-color-primary-rgb: 82, 96, 157;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #48548a;
    --ion-color-primary-tint: #6370a7;

    --ion-color-secondary: #addbe0;
    --ion-color-secondary-rgb: 173, 219, 224;
    --ion-color-secondary-contrast: #000000;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #98c1c5;
    --ion-color-secondary-tint: #b5dfe3;

    --ion-color-tertiary: #a4accc;
    --ion-color-tertiary-rgb: 164, 172, 204;
    --ion-color-tertiary-contrast: #000000;
    --ion-color-tertiary-contrast-rgb: 0, 0, 0;
    --ion-color-tertiary-shade: #9097b4;
    --ion-color-tertiary-tint: #adb4d1;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-toolbar-background: #0d0d0d;

    --ion-item-background: #1c1c1c;
    --ion-item-background-activated: #313131;
  }

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1a1b1e;
  }

  ion-title.title-large {
    --color: white;
  }
}*/

ion-list {
  --padding: 0;
  --margin: 0;
}

.pointer {
  cursor: pointer;
}

.link-button {
  font-size: 14px;
  cursor: pointer;
  color: var(--ion-color-primary);

  &.danger {
    color: var(--ion-color-danger);
  }
}

.subtext {
  font-size: 12px;
  color: var(--ion-color-step-550);
}

.label {
  display: block;
  font-size: 12px;
  color: var(--ion-color-step-500);
  margin-bottom: 5px;
}

ion-progress-bar {
  border-radius: 8px;
  --progress-background: var(--ion-color-basketball);
}

.form-select-popover {
  --min-width: 220px;
  --width: auto;
}

.workflow-enter {
  opacity: 0;
}
.workflow-enter-active {
  opacity: 1;
  transition: opacity 0.25s ease-in;
}
.workflow-exit {
  opacity: 1;
}
.workflow-exit-active {
  opacity: 0;
  transition: opacity 0.25s ease-in;
}

.timeline--container.has-selection .timeline--event:not(.selected) {
  filter: brightness(50%);
}

.timeline--event {
  height: 10px;
  width: 10px;
  position: absolute;
  z-index: 1;

  &:before {
    content: '';
    display: block;
    height: 10px;
    width: 10px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 2;
  }

  &.with-marker {
    &:after {
      background: white;
      content: '';
      display: block;
      height: 14px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, calc(100% - 1px));
      width: 1px;
      z-index: 2;
    }
  }

  &.shot_made:before,
  &.shot_miss:before {
    background: var(--ion-color-success);
    border-radius: 100%;
    color: var(--ion-color-success-contrast);
  }

  &.shot_miss:before {
    border: solid 1px var(--ion-color-success);
    background: transparent;
    height: 8px;
    width: 8px;
  }

  &.shot_made:after,
  &.shot_miss:after {
    background: var(--ion-color-success);
  }

  &.foul:before {
    background: var(--ion-color-danger);
    color: var(--ion-color-success-danger);
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  }

  &.foul:after {
    background: var(--ion-color-danger);
  }

  &.turnover:before {
    background: var(--ion-color-basketball);
    color: var(--ion-color-basketball-contrast);
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  }

  &.turnover:after {
    background: var(--ion-color-basketball);
  }

  &.flagged:before {
    background: var(--ion-color-warning);
    color: var(--ion-color-warning-contrast);
    clip-path: polygon(7% 0, 91% 0, 91% 100%, 86% 100%, 86% 61%, 7% 61%);
  }

  &.flagged:after {
    background: var(--ion-color-warning);
  }
}

.one-drive-picker {
  background-color: white;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
}
.tooltip-class {
  .popover-arrow.sc-ion-popover-ios::after {
    background-color: #2e2e2e;
  }
  .popover-content {
    background-color: #2e2e2e;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    min-width: 5%;
    text-align: center;
  }
}

ion-modal.impersonate-modal .modal-wrapper {
  --height: auto;
  .ion-page {
    position: relative;
    display: block;
    contain: content;
    .inner-content {
      max-height: 30vh;
      overflow: auto;
      padding: 10px;
    }
  }
}
.impersonation-active-message {
  position: fixed;
  z-index: 100;
  min-width: 25%;
  background-color: #555555;
  color: #eeeeee;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  bottom: 10%;
  right: 10%;
  padding: 14px 10px 5px 25px;
  text-align: center;
  border-radius: 25px;
  min-height: 35px;
  .inner-message {
    float: left;
    padding: 0 0 0 2px;
    width: auto;
  }
  .inner-icon {
    font-size: 20px;
    float: right;
    padding: 0 0 0 5px;
  }
}
